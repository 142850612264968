import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class DeploymentDTO
{
    id?: Nullable<number>;
    release_id?: Nullable<number>;
    guid?: Nullable<string>;
    project_code?: Nullable<string>;
    project_name?: Nullable<string>;
    project_url?: Nullable<string>;
    deployment_date?: Nullable<string>;
    author?: Nullable<string>;

    constructor({ id, release_id, guid, project_code, project_name, project_url, deployment_date, author}: {id?: Nullable<number>, release_id?: Nullable<number>, guid?: Nullable<string>, project_code?: Nullable<string>, project_name?: Nullable<string>, project_url?: Nullable<string>, deployment_date?: Nullable<string>, author?: Nullable<string>}) {
        this.id = id;
        this.release_id = release_id;
        this.guid = guid;
        this.project_code = project_code;
        this.project_name = project_name;
        this.project_url = project_url;
        this.deployment_date = deployment_date;
        this.author = author;
    }
}

export default class Deployment extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private release_id: number;
    private guid: Nullable<string>;
    private project_code: string;
    private project_name: string;
    private project_url: string;
    private deployment_date: string;
    private author: string;

    constructor(
        id: Nullable<number>,
        release_id: number,
        guid: Nullable<string>,
        project_code: string,
        project_name: string,
        project_url: string,
        deployment_date: string,
        author: string
    ) {
        super();
        this.id = id;
        this.release_id = release_id;
        this.guid = guid;
        this.project_code = project_code;
        this.project_name = project_name;
        this.project_url = project_url;
        this.deployment_date = deployment_date;
        this.author = author;
    }

    static create(dto: DeploymentDTO): Deployment
    {
        return new Deployment(
            dto.id,
            dto.release_id,
            dto.guid,
            dto.project_code,
            dto.project_name,
            dto.project_url,
            dto.deployment_date,
            dto.author
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setReleaseId(release_id: number): void
    {
        this.release_id = release_id;
    }

    getReleaseId(): number
    {
        return this.release_id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setProjectCode(project_code: string): void
    {
        if (project_code.length > 255) {
            throw new NameTooLong();
        }
        this.project_code = project_code;
    }

    getProjectCode(): string
    {
        return this.project_code;
    }

    setProjectName(project_name: string): void
    {
        if (project_name.length > 255) {
            throw new NameTooLong();
        }
        this.project_name = project_name;
    }

    getProjectName(): string
    {
        return this.project_name;
    }

    setProjectUrl(project_url: string): void
    {
        if (project_url.length > 255) {
            throw new NameTooLong();
        }
        this.project_url = project_url;
    }

    getProjectUrl(): string
    {
        return this.project_url;
    }

    setDeploymentDate(deployment_date: string): void
    {
        this.deployment_date = deployment_date;
    }

    getDeploymentDate(): string
    {
        return this.deployment_date;
    }

    setAuthor(author: string): void
    {
        this.author = author;
    }

    getAuthor(): string
    {
        return this.author;
    }

}
